.card__image {
  margin: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
  transform: scale(1);
  transition: transform 0.3s ease;
  transform-origin: bottom;
  background-image: url("../../../images/card-image-placeholder.svg");
  /* TODO hide bg image if image is loaded */
}

.card__image:hover {
  cursor: pointer;
  transform: scale(1.01);
  transition: transform 1s ease;
  transform-origin: bottom;
}
