.profile {
  width: 100%;
  padding: 40px 0 25px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

@media (max-width: 768px) {
  .profile {
    display: flex;
    flex-flow: column;
    align-items: center;
  }
}

@media (max-width: 375px) {
  .profile {
    padding: 43px 0 18px;
  }
}
