.profile__name {
  margin: 0 19px 0 0;
  color: #fff;
  font-size: 42px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.14;
  max-width: 405px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media (max-width: 768px) {
  .profile__name {
    font-size: 36px;
    margin: 0 0 8px 0;
    width: 100%;
    align-items: center;
    text-align: center;
  }
}

@media (max-width: 530px) {
  .profile__name {
    max-width: 270px;
  }
}

@media (max-width: 375px) {
  .profile__name {
    margin: 0;
    max-width: 191px;
    font-weight: 500;
    font-size: 27px;
    line-height: 1.222;
  }
}
