.profile__header {
  margin: 22px 0 0 0;
  display: flex;
  align-items: center;
  position: relative;
}

@media (max-width: 375px) {
  .profile__header {
    margin: 0 0 8px 0;
  }
}
