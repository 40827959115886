.form__input-error-hint {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #ff0000;
  display: none;
  position: absolute;
  left: 0;
  top: 40px;
}
