.profile__main {
  width: 100%;
  max-width: 1280px;
  display: flex;
  justify-content: center;
  position: relative;
}

@media (max-width: 768px) {
  .profile__main {
    display: flex;
    align-items: center;
  }
}
