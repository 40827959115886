.header__logo {
  background-image: url("../../../images/logo_white.svg");
  width: 142px;
  height: 33px;
  background-size: contain;
  background-repeat: no-repeat;
}

@media (max-width: 375px) {
  .header__logo {
    margin: 0 0 0 4px;
    width: 104px;
    height: 25px;
  }
}
