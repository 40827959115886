.button {
  padding: 0;
  border: 1px solid #fff;
  box-sizing: border-box;
  background-color: #000;
  color: transparent;
  transition: opacity cubic-bezier(0, 0.6, 0.82, 1.03) 1s;
}

.button:hover {
  cursor: pointer;
  opacity: 0.6;
  transition: opacity cubic-bezier(0, 0.6, 0.82, 1.03) 0.3s;
}
