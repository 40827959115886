.form {
  width: min(calc(100vw - 60px), 430px);
  padding: 34px 36px 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #fff;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  border: 0;
}
