.cards-grid {
  margin: 0 auto;
  width: 100%;
  flex-direction: column;
  list-style: none;
  padding: 26px 0;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(auto-fill, 282px);
  column-gap: 17px;
  row-gap: 20px;
  justify-content: center;
}

@media (max-width: 640px) {
  .cards-grid {
    max-width: 350px;
    padding: 18px 0;
    display: flex;
    flex-direction: column;
    align-items: normal;
    justify-items: flex-start;
  }
}

@media (max-width: 375px) {
  .cards-grid {
    display: grid;
  }
}
