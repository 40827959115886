.divider {
  position: absolute;
  background-color: #545454;
  opacity: 0.7;
  height: 1px;
  width: 100%;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
}

@media (max-width: 375px) {
  .divider {
    width: 375px;
  }
}

@media (max-width: 320px) {
  .divider {
    width: 320px;
  }
}
