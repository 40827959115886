.tooltip__title {
  padding: 0;
  margin: 32px 0 0 0;
  color: #000;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
}

@media (max-width: 375px) {
  .tooltip__title {
    margin: 40px 0 0 0;
    font-size: 20px;
    line-height: 24px;
  }
}
