.profile__photo-overlay {
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  margin: 0;
  transform: translate(0, 0);
  background: url("../../../images/updateProfilePhotoButton.svg"),
    rgba(0, 0, 0, 0.5);
  background-size: 26px;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0;
  transition: opacity cubic-bezier(0, 0.6, 0.82, 1.03) 0.8s,
    background cubic-bezier(0, 0.6, 0.82, 1.03) 1s;
}

.profile__photo-overlay:hover {
  cursor: pointer;
  opacity: 1;
  transition: opacity cubic-bezier(0, 0.6, 0.82, 1.03) 0.3s,
    background cubic-bezier(0, 0.6, 0.82, 1.03) 1s;
}

@media (max-width: 768px) {
  .profile__photo-overlay {
    left: 50%;
    transform: translate(-50%, 0);
  }
}
