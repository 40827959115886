.footer__copyright {
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.22;
  color: #545454;
}

@media (max-width: 375px) {
  .footer__copyright {
    font-size: 14px;
    line-height: 1.214;
  }
}
