.profile__about {
  margin: 8px 0 0 0;
  color: #fff;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.222;
  max-width: 40ch;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media (max-width: 768px) {
  .profile__about {
    margin: 0 auto;
    text-align: center;
    width: 100%;
    align-items: center;
  }
}

@media (max-width: 530px) {
  .profile__about {
    max-width: 350px;
  }
}

@media (max-width: 375px) {
  .profile__about {
    font-size: 14px;
    line-height: 1.214;
  }
}
