.popup__backdrop {
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
}
