.form__header {
  margin: 0 0 41px 0;
  padding: 0;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 1.208;
  color: #000000;
  text-align: left;
}

@media (max-width: 375px) {
  .form__header {
    margin: 0 0 68px 0;
    font-size: 18px;
    line-height: 22px;
  }
}
