.header {
  margin: 0 auto;
  padding: 45px 0 41px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
}

@media (max-width: 450px) {
  .header {
    padding: 28px 0 30px;
    flex-direction: column-reverse;
  }
}

@media (max-width: 375px) {
  .header {
    padding-left: 7px;
    padding-right: 7px;
  }
}
