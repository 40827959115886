.preloader {
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  align-items: center;
  padding: 76px 0;
  box-sizing: border-box;
  background-color: #000;
  display: flex;
  z-index: 2;
}
