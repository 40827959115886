.profile__container {
  display: flex;
  position: relative;
}

@media (max-width: 768px) {
  .profile__container {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}
