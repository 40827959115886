.page {
  margin: 0 auto;
  padding: 0 27px 0;
  max-width: 880px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 375px) {
  .page {
    padding: 0 19px 0;
  }
}
