@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../../vendor/fonts/inter/Inter-Regular.woff?v=3.19") format("woff2"),
    url("../../vendor/fonts/inter/Inter-Regular.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../../vendor/fonts/inter/Inter-Medium.woff2?v=3.19") format("woff2"),
    url("../../vendor/fonts/inter/Inter-Medium.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("../../vendor/fonts/inter/Inter-Black.woff2?v=3.19") format("woff2"),
    url("../../vendor/fonts/inter/Inter-Black.woff?v=3.19") format("woff");
}
