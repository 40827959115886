.root {
  background-color: #000;
  margin: 0;
  padding: 0;
  font-family: Inter, 'Helvetica Neue', Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  color: #fff;
}
