.profile__photo {
  width: 120px;
  height: 120px;
  margin: 0 30px 0 0;
  border-radius: 50%;
  object-position: center;
  object-fit: cover;
  background-image: url("../../../images/avatar-placeholder.svg");
}

@media (max-width: 768px) {
  .profile__photo {
    margin: 0 0 26px 0;
  }
}
