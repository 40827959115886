.card {
  display: flex;
  flex-direction: column;
  position: relative;
  width: max(282px, 100%);
  min-height: 361px;
  border-radius: 10px;
  overflow: hidden;
  background-color: transparent;
}
