.spinner {
  width: 50px;
  height: 50px;

  border: 2px solid #f3f3f333;
  border-top: 3px solid #f3f3f3;
  border-radius: 1000%;

  animation: spin 2.2s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
