.footer {
  padding: 41px 0 60px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
}

@media (max-width: 375px) {
  .footer {
    padding: 30px 0 36px;
  }
}
