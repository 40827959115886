.card__title {
  margin: 0;
  padding: 0 20px 0 0;
  box-sizing: border-box;
  width: 100%;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
