.footer__link {
  font-family: 'Cascadia Code', 'Jetbrains Mono', 'Fira Mono', 'Lucida Console', 'Consolas', 'Courier New', Courier, monospace;
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 1.2;
  color: #545454;
  align-self: bottom;
}

@media (max-width: 375px) {
  .footer__link {
    font-size: 12px;
    line-height: 1.1;
  }
}
