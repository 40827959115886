.form__input {
  border: 0;
  margin: 0 0 23px 0;
  width: 100%;
  height: 34px;
  padding: 6px 0;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  background-color: transparent;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.214;
}

.form__input:focus {
  border: 0;
  outline: 0;
  box-sizing: border-box;
  border-bottom: 2px solid rgba(0, 0, 0, 0.8);
}

.form__input::placeholder {
  color: #000;
  opacity: 0.6;
}

@media (max-width: 375px) {
  .form__input {
    padding: 0 0 3px 0;
  }
}
